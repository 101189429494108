/* global Component */
class itemViewModuleComponent extends Component {

    static name() {
        return "itemViewModuleComponent";
    }

    static componentName() {
        return "itemViewModuleComponent";
    }

    getProps() {
        return ['item'];
    }

    data() {
        return {
            slidesItem:[],
            itemInCar:0,
            Qty:1,
            showCarusel:false,
            alertMsg:null,
            stockQty:null,
            discount: false,
            styledObservation: null
        };
    }

    getWatch() {
        return {
            "Qty":function (newValue, oldValue){
                if(newValue && this.maxItemInCar > 0) {
                    this.Qty = newValue; //this.positiveIntegerformat(newValue,1,this.maxItemInCar);
                    if (newValue && this.controlStock) {
                        if (Number.isInteger(this.item.Qty) && this.item.Qty <= this.Qty) {
                            this.Qty = this.item.Qty;
                            this.alertShowMessage("Maximum item quantity reached");
                        }
                    }
                }
            }
        };
    }

    getComputed() {
        return {
            getQtyFormulaFields:function (){
                return this.$store.getters.getQtyFormulaFields;
            },
            canUseOrderQtyFormula:function (){
                return this.$store.getters.canUseOrderQtyFormula;
            },
            maxItemInCar:function (){
                return this.getMaxStockByCode(this.item.Code);
            },
            showStock:function (){
                if(!this.$store.getters.getIsLogin)
                    return false;
                return this.$store.getters.showStock;
            },
            controlStock(){
                return this.$store.getters.getControlStock;
            },
            canShopItem(){
                return this.controlStock ? this.item.Qty > 0 : true;
            },
            noStock:function () {
                if(this.item.Qty <= 0)
                    return true;
                return false;
            },
            currentItemPrice:function (){
                // eslint-disable-next-line no-unused-vars
                let test = this.$store.state.itemTimeFlag;
                // eslint-disable-next-line no-unused-vars
                let test2 = this.$store.state.itemUpdate;
                let currentPrice = this.$store.getters.showItemPrice(this.item);
                return currentPrice;
            },
            mainImage:function (){
                if(this.item.slideIds) {
                    let imagesList = this.item.slideIds.split(",");
                    this.item.Images = [];
                    for (let item of imagesList) {
                        this.slidesItem.push(`/oo/api/get_attached_file?OriginRecordName=Item&OriginId=${this.item.Code}&id=${item}`);
                    }
                    return this.slidesItem[0];
                }
                return null;
            },
            renderStock:function () {
                if(this.stockFlag == undefined || this.stockFlag)
                    return true;
                return false;
            },
            itemMustBeShow(){
                if(this.item.isCalculate) {
                    if (this.currentItemPrice == 0) {
                        return this.$store.ge;
                    }
                }
                return true;
            },
            Currency:function () {
                return this.$store.getters.getCurrency;
            },
            isItemVariation: function () {
                return this.item.varieties_children && this.item.varieties_children.length > 0;
            },
            getStyledObservation: function (){
                let item = this.item;
                if(item) {
                    let comment = item.StyledObservation ?  item.StyledObservation.replaceAll('"', "'") : null;  
                    this.styledObservation = comment 
                    return comment;
                }
            }
        };
    }

    getMethods() {
        return {
            updateStockQty:this.updateStockQty,
            alertShowMessage:this.alertShowMessage,
            openItemView:this.openItemView,
            ItemComponentAddToCar:this.ItemComponentAddToCar,
            itemQtyIncrement:this.itemQtyIncrement,
            itemQtyDecrement:this.itemQtyDecrement,
            showItemPrice: this.showItemPrice,
            toggleFavorite:this.toggleFavorite,
            goToDetail:this.goToDetail,
            getDetailUrl:this.getDetailUrl,
            getMaxStockByCode: this.getMaxStockByCode,
            updateQtyFormula: this.updateQtyFormula,
        };
    }

    updateStockQty(){
        let itemStore = this.$store.getters.getItemByCode(this.item.Code);
        this.stockQty = itemStore.Qty;
    }

    alertShowMessage(alertMsg){
        this.alertMsg = alertMsg;
        setTimeout(()=> this.alertMsg = null, 4500);
    }

    openItemView(item){
        if(item)
            this.$router.push('/view/' + this.encodeToURL(item.Code));
    }

    getMaxStockByCode(itemCode){
        let giftCardItem = this.$store.getters.getGiftCardItem;
        if(this.$store.getters.getSetting.ControlStockForSales &&  !(giftCardItem && giftCardItem.fields.Code == itemCode)) {
            let itemInStore = this.$store.getters.getItemByCode(itemCode);
            const qtyInCar = this.$store.getters.getItemAmountInCar(itemCode);
            if(itemInStore.Reserved)
                return itemInStore.Qty - itemInStore.Reserved - qtyInCar;
            return itemInStore.Qty - qtyInCar;
        }
        return this.$store.getters.getMaxQtyItem - this.$store.getters.getItemQtyCar(this.item.Code);
    }

    updateQtyFormula(field, event) {
        let fieldValue = event.target.valueAsNumber;
        this.item[field] = fieldValue;
    }

    ItemComponentAddToCar(){
        if(isNaN(this.Qty) || parseInt(this.Qty) == 0) {
            this.alertShowMessage(`Incorrent Qty Value`);
            return;
        }
        if(this.item.ItemType == 6)
            return this.goToDetail();
        if(this.controlStock) {
            let itemInCar = this.$store.getters.getItemCar.filter(x => x.id == this.item.Code)[0];
            const maxQty = this.getMaxStockByCode(this.item.Code);
            if (itemInCar) {
                if (parseInt(itemInCar.cant) + parseInt(this.Qty) > maxQty) {
                    if(maxQty === 0)
                        this.alertShowMessage("ITEM_STOCK_ZERO");
                    else
                        this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                    return;
                }
            }
            else{
                if (parseInt(this.Qty) > maxQty) {
                    if(maxQty === 0)
                        this.alertShowMessage("ITEM_STOCK_ZERO");
                    else
                        this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                    return;
                }
            }
        }
        this.item.carQty = this.Qty;
        if(this.canUseOrderQtyFormula) {
            this.$store.dispatch('addToCarWithQtyFormula',this.item).then(()=>{
                for(let field of this.getQtyFormulaFields)
                    this.item[field] = null;
            });
        } else {
            this.$store.dispatch('ItemComponentAddToCar',this.item).then(()=>{
                const currency = this.$store.state.priceDeal.priceDeal ? 
                    this.$store.state.priceDeal.priceDeal.fields.Currency :
                    this.$store.state.setting.Currency;
                this.gtmTrackEcommerceAddToCart(this.item,  this.Qty, currency, this.Qty * (this.item.RowTotal || this.item.RowNet || this.item.Price));
                this.Qty = 1;
            });
        }
    }

    itemQtyIncrement(){
        if(Number.isInteger(this.Qty))
            this.Qty += 1;
        else
            this.Qty = 1;
    }

    itemQtyDecrement(){
        if(this.Qty)
            this.Qty -= 1;
        else
            this.Qty = 0;
    }

    showItemPrice(item){
        let price = item.Price;
        if(this.$store.getters.getPriceToShow)
            price = item.RowTotal;
        else{
            if(item.RowNet)
                price = item.RowNet;
        }
        return parseFloat(Math.round(price * 100) / 100).toFixed(2);
    }

    toggleFavorite(itemCode){
        this.$store.dispatch('toggleFavourite',itemCode);
        this.favorite = !this.favorite;
    }

    goToDetail(){
        this.$router.push('/view/' + this.encodeToURL(this.item.Code));
    }

    getDetailUrl() {
        return `/detail/${encodeURIComponent(this.urlSanitize(this.item.Classification_format)) || "generic"}/${encodeURIComponent(this.urlSanitize(this.item.Code))}/${encodeURIComponent(this.urlSanitize(this.item.Name))}`;
    }

    getTemplate() {
        return `<div class="card">
                    <div class="tag-item-module" v-if="item.NewItem || item.Outstanding">
                        <h6 class="tag-item-new" v-if="item.NewItem">{{tr('New Item')}}</h6>
                        <h6 class="tag-item-outstanding" v-if="item.Outstanding">{{tr('Outstanding')}} </h6>
                    </div>
                    <div class="discount" v-if="item.Outstanding">
                        <h1>{{tr('Outstanding')}}</h1>
                    </div>
                    <div class="discount" v-if="discount && item.CrossedOutPrice">
                        <discountComponent :item="item" :currentItemPrice="currentItemPrice" />
                    </div>
                    <div class="favorite-icon" v-if="$store.getters.getSession!=null">
                        <favoriteBtnComponent :itemCode="item.Code" />
                    </div>
                    <template v-if="$store.getters.getSetting && $store.getters.getSetting.DemoPhotos">
                        <router-link :to="getDetailUrl()">
                            <div class="card-image item-detail-modal">
                                <itemSliderComponent :key="'slide'+item.Code" :item="item" :stockFlag="$store.getters.showStock" :container="false"></itemSliderComponent>
                            </div>
                        </router-link>
                    </template>
                    <div class="card-body">
                        <h5 class="card-title">
                            <p class="card-text item-name">{{item.Name}}</p>
                            <p class="card-text item-name d-none" v-if="showStock">Stock {{item.stockQty}}</p>
                        </h5>

                        <div v-if="getStyledObservation" v-html="styledObservation"></div>

                        <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                            <priceComponent :item="item"></priceComponent>
                            <p v-if="item.PriceByUnit" class="card-text mb-3 item-price-by-unit"><small>{{item.PriceByUnit}}</small></p>
                        </template>
                        <div class="form-row">
                            <template v-if="alertMsg">
                                <div class="alert alert-danger" role="alert">{{tr(alertMsg)}}</div>
                            </template>
                            <div class="form-group col-8" v-if="!isItemVariation">
                                <template v-if="!canUseOrderQtyFormula">
                                    <template v-if="controlStock">
                                        <template v-if="item.Qty>0">
                                            <input :ref="'carCant-'+item.Code" v-model="Qty" class="form-control" min="1" :max="maxItemInCar" type="number">
                                        </template>
                                        <template v-else>
                                            <input :ref="'carCant-'+item.Code" v-model="Qty" class="form-control" min="1" :max="maxItemInCar" type="number" disabled aria-describedby="withOutStockHelpBlock">
                                            <small id="withOutStockHelpBlock" class="form-text text-muted">
                                                {{tr("Without Stock")}}
                                            </small>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <input :ref="'carCant-'+item.Code" v-model="Qty" class="form-control" min="1" :max="maxItemInCar" type="number" :disabled="maxItemInCar<1">
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-for="field of getQtyFormulaFields">
                                        <input :ref="field+'-'+item.Code" :placeholder="tr(['Enter {{$1}}',field])" class="form-control" min="1" :max="1000000" type="number" :disabled="maxItemInCar<1" @change="(event) => updateQtyFormula(field, event)">
                                    </template>
                                </template>
                            </div>
                            <div :class="{col:isItemVariation, 'col-4': !isItemVariation}"  class="text-center" :disabled="maxItemInCar<1">
                                <div class="cart-button" :title="tr('Add To Basket')" :class="{'disabled': !canShopItem}" @click.stop="canShopItem ? ItemComponentAddToCar(item) : null">
                                    <span class="icon"><i class="fa-icon fas fa-cart-plus"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`;
    }
}

itemViewModuleComponent.registerComponent();
